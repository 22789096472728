import React from "react"
import { graphql } from "gatsby"

// Handles all Wordpress posts
export default function Post(props) {
  const {
    data: {
      wordpressPost: { title, content },
    },
  } = props
  return (
    <div>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPost {
      title
      content
    }
  }
`
